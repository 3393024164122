.tippy-box {
  background: white;
  border-radius: 20px;
}

.tippy-box:after {
  content: " ";
  background: transparent linear-gradient(90deg, #013243 0%, #042E3D 12%, #0D252D 27%, #181C1D 38%, #ED5212 71%, #FCBD24 99%);
  height: 20px;
  display: block;
  position: relative;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.tippy-content {
  padding: 1em;
  color: #162737;
  font-size: 20px;
  letter-spacing: 0;
}

.tippy-box[data-placement^='top'] > .tippy-arrow::before {
  border-top-color: white;
}
.tippy-box[data-placement^='bottom'] > .tippy-arrow::before {
  border-bottom-color: white;
}
.tippy-box[data-placement^='left'] > .tippy-arrow::before {
  border-left-color: white;
}
.tippy-box[data-placement^='right'] > .tippy-arrow::before {
  border-right-color: white;
}
